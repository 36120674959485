<!--
---  HOW TO USE  ---

To navigate through the steps, adjust the 'currentStep' variable in the parent component. The 'currentStep' should be increased or decreased within the bounds of 0 and 'numSteps - 1' to step through the steps properly.

Example usage in a parent component:

<script setup lang="ts">

// Initialize the current step
const currentStep = ref(0);

// Function to move to the next step
function nextStep() {
    if (currentStep.value < numSteps - 1) {
        currentStep.value++;
    }
}

// Function to move to the previous step
function prevStep() {
    if (currentStep.value > 0) {
        currentStep.value--;
    }
}

// Define the total number of steps, step titles, and step icons (optional)
const numSteps = 3;
const stepTitles = ["Foo", "Bar", "Baz"];
const stepIcons = ["i-sl-arrow-right-1", "i-sl-task-checklist", "i-sl-arrow-right-1"];
</script>

// The 'SharedStepIndicator' component visualizes the steps. Ensure 'numSteps' and 'currentStep' are correctly passed as props.
<SharedStepIndicator :numSteps="numSteps" :currentStep="currentStep" />

Note: The 'numSteps' and 'currentStep' props are required for the 'SharedStepIndicator' component to function properly. 'stepTitles' and 'stepIcons' are optional and provide additional visual cues.
-->

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    currentStep: number;
    numSteps: number;
    stepTitles?: string[];
    stepIcons?: string[];
    mobileHidelabel?: boolean;
  }>(),
  {
    stepTitles: () => [],
    stepIcons: () => [],
    mobileHidelabel: false,
  },
);

const emit = defineEmits<{
  (event: "update-step", step: number): void;
}>();

const setStep = (step: number) => {
  emit("update-step", step);
};
</script>

<template>
  <div class="flex flex-row justify-between items-center sm:px-5">
    <template v-for="index in numSteps" :key="index">
      <div
        v-if="index > 1"
        class="flex-grow h-2px mx-3"
        :class="
          index - 1 <= currentStep
            ? 'bg-scheppach-primary-300'
            : 'bg-scheppach-neutral-200'
        "
      ></div>
      <div
        class="flex flex-col items-center"
        :class="[
          currentStep >= index - 1 ? 'cursor-pointer' : 'cursor-default',
        ]"
        @click="setStep(index)"
      >
        <!-- The box -->
        <div
          class="relative flex justify-center items-center w-12 h-12"
          :class="
            index - 1 <= currentStep
              ? 'bg-scheppach-primary-500 text-white'
              : 'bg-scheppach-neutral-200 text-scheppach-neutral-400'
          "
        >
          <span
            v-if="stepIcons[index - 1] !== undefined"
            class="text-2xl"
            :class="stepIcons[index - 1]"
          ></span>
          <span v-else>
            {{ index }}
          </span>

          <!-- The optional text -->
          <div
            class="w-200px absolute text-center bottom--7"
            :class="[
              index - 1 <= currentStep
                ? 'text-scheppach-primary-500'
                : 'text-scheppach-neutral-300',
              mobileHidelabel ? 'hidden md:block' : 'block',
            ]"
          >
            {{
              stepTitles[index - 1] === undefined ? "" : stepTitles[index - 1]
            }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
